import React, { useEffect } from 'react'
import ContactUsForm from '../components/ContactUsForm'
import FooterPanel from '../components/FooterPanel'
import Navbar from '../components/Navbar';

export default function ContactUsPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="PageContainer">
      <Navbar />
      <ContactUsForm />
      <FooterPanel />
    </div>
  )
}
