import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import * as ROUTES from './routes';
import LandingPage from './containers/LandingPage';
import ContactUsPage from './containers/ContactUsPage';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <Router>
      <Routes>
        <Route exact path={ROUTES.LANDING} element={<LandingPage />} />
        <Route exact path={ROUTES.CONTACT_US} element={<ContactUsPage />} />
        <Route path="*" element={<LandingPage />} />
        {/* all other pages redirect to landing */}
      </Routes>
    </Router>
  );
}

export default App;
