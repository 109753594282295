export const getDoesRouteMatchCurrentPage = (route, location) => {
  if(!!route) {
    if(!!location && !!location.pathname) {
      const parsedRoute = route.replace("/","");
      const parsedPathname = location.pathname.replace("/","");
      if(parsedRoute === parsedPathname) {
        return true;
      }
    }
  }
}
