import React from 'react'
import { useNavigate } from "react-router-dom";
import { getDoesRouteMatchCurrentPage } from "../services/url";

export default function ContactUsButton() {
  const navigate = useNavigate();

  const handleRedirect = (e, route) => {
    e.preventDefault();
    if (!!route) {
      const doesRouteMatchCurrentPage = getDoesRouteMatchCurrentPage(route, !!window && window.location);
      if (doesRouteMatchCurrentPage) {
        !!window && !!window.scrollTo && window.scrollTo(0, 0);
      } else {
        return navigate(route);
      }
    }
  }
  return (
    <button className='text-medium mt-3 action-button' onClick={e => handleRedirect(e, '/contact')}>Contact Us</button>
  )
}
