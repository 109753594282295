import axios from 'axios';
import React, { useState } from 'react'
import { Alert, Col, Form, Row, Spinner } from 'react-bootstrap';
import './ContactUsForm.css';

export default function ContactUsForm() {
  const initialState = {
    isLoading: false,
    submitted: false,
    error: false,
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    notes: ''
  }
  const [state, setState] = useState(initialState);

  const handleChange = e => {
    setState({
      ...state,
      [e.target.name]: e.target.value
    })
  }

  const handleSubmit = e => {
    e.preventDefault();
    console.log("handleSubmit");
    console.log(state);
    setState({
      ...state,
      isLoading: true
    });
    const data = {
      firstName: state.firstName,
      lastName: state.lastName,
      email: state.email,
      phone: state.phone,
      notes: state.notes
    }
    const url = "https://hooks.zapier.com/hooks/catch/13395083/bco7s3x/";
    axios.post(url, JSON.stringify(data), {
      transformRequest: [function (data, headers) {
        delete headers.post['content-Type'];
        delete headers.get['content-Type'];
        delete headers.common['content-Type'];
        return data;
      }]
    })
      .then((res) => {
        setState({
          ...state,
          isLoading: false,
          submitted: true
        });
      }).catch(error => {
        console.log(error);
        setState({
          ...state,
          isLoading: false,
          error
        })
      })
  }

  const {
    isLoading,
    submitted,
    firstName,
    lastName,
    email,
    phone,
    notes,
    error
  } = state;
  if (isLoading) {
    return (
      <Row>
        <Col className="text-center">
          <p className="text-large text-center">Contact Us</p>
          <Spinner animation="border" variant="light" />
        </Col>
      </Row>
    )
  } else if (submitted) {
    return (
      <>
        <Row>
          <Col lg={6}>
            <p className="text-large text-center">Contact Us</p>
            <Alert variant="success">
              Your submission was successful.  Thank you for reaching out!
            </Alert>
          </Col>
        </Row>
      </>
    )
  } else if (error) {
    return (
      <>
        <Row>
          <Col lg={6}>
            <p className="text-large text-center">Contact Us</p>
            <Alert variant="warning">
              We're sorry - it looks like your submission didn't go through.  Could you please reach out to us directly at <a href="mailto:info@resoursify.com" className="text-orange">info@resoursify.com</a>?  Thanks for your understanding!
            </Alert>
          </Col>
        </Row>
      </>
    )
  } else {
    return (
      <>
        <div>
          <p className="text-large text-center mt-6">Contact Us</p>
          <Form onSubmit={handleSubmit} className="form-grid-container">
            <Form.Group className="mb-3" controlId="firstName">
              <Form.Label>First Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="First Name"
                name="firstName"
                value={firstName}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="lastName">
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Last Name"
                name="lastName"
                value={lastName}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="email">
              <Form.Label>Email Address</Form.Label>
              <Form.Control
                type="email"
                placeholder="Email"
                name="email"
                value={email}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="phone">
              <Form.Label>Phone</Form.Label>
              <Form.Control
                type="tel"
                placeholder="Phone"
                name="phone"
                value={phone}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group className="mb-3 span-2-desktop" controlId="notes">
              <Form.Label>Notes</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Notes"
                name="notes"
                value={notes}
                onChange={handleChange}
              />
            </Form.Group>
            <button
              type="submit"
              className='text-medium mt-3 action-button display-block center span-2-desktop mb-10-mobile'
            >
              Submit
            </button>
          </Form>
        </div>
      </>
    )
  }
}
