import React, { useEffect } from 'react';
import { useNavigate } from 'react-router';

import stockPhoto1 from "../images/brands-people-Ax8IA8GAjVg-unsplash.jpg";
import stockPhoto2 from "../images/hannah-busing-Zyx1bK9mqmA-unsplash.jpg";
import stockPhoto3 from "../images/absolutvision-82TpEld0_e4-unsplash.jpg";
import stockPhoto4 from "../images/david-travis-WC6MJ0kRzGw-unsplash.jpg";

import FooterPanel from '../components/FooterPanel';

import ContactUsButton from '../components/ContactUsButton';
import Navbar from '../components/Navbar';

export default function LandingPage() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="PageContainer">
      <Navbar />
      <div className="grid-container relative">
        {/* panel 1 */}
        <div className="grid-element flex-center vh-100">
          <div className="w-75 m-0-auto left-center-responsive">
            <p className='text-ubuntu-title'>resoursify</p>
            <p className='text-large mt-3'>Every business has room to grow.</p>
            <p className='text-large mt-3'>Let's do it together!</p>
            <ContactUsButton />
          </div>
        </div>
        <div className="grid-element flex-center">
          <img className="stock-image w-75 center" src={stockPhoto1} alt="project notes" />
        </div>

        {/* panel 2 */}
        <div className="grid-element flex-center span-2-desktop">
          <div className="text-center w-65 center">
            <p className="text-large"><span className="text-orange">Resoursify</span> is a startup designed to help businesses improve their operations.</p>
            <div className="w-90 center">
              <p className="text-small mt-3">Growing a business is often a daunting task. Limited resources, multiple fronts, new departments with no experience, missing SLAs and standard operating procedures. If any or all of this sounds familiar, we can help!</p>
              <p className="text-small mt-3">25+ years of combined experience in developing processes and operations for various businesses. Bring an out-of-the-box view to your company!</p>
            </div>
          </div>
        </div>

        {/* panel 3 */}
        <div className="grid-element flex-center">
          <div className="w-75 m-0-auto left-center-responsive">
            <div className="flex-basis-50">
              <p className='text-large text-orange'><span style={{ "display": "block" }}>Adding </span><span style={{ "display": "block" }}>Workforce</span></p>
              <p className='text-medium mt-3'>Sometimes, all you need is an extra set of hands to speed things up.</p>
              <p className='text-medium mt-3'>Our agents are highly skilled and extremely fast learners. They are trained to enter new environments and bring a fresh view.</p>
            </div>
          </div>
        </div>
        <div className="grid-element flex-center">
          <img className="stock-image  w-75 center" src={stockPhoto2} alt="project notes" />
        </div>

        {/* panel 4 */}
        <div className="grid-element flex-center">
          <img className="stock-image  w-75 center" src={stockPhoto3} alt="project notes" />
        </div>
        <div className="w-75 m-0-auto left-center-responsive">
          <div className="flex-basis-50">
            <p className='text-large text-orange'><span style={{ "display": "block" }}>Improving </span><span style={{ "display": "block" }}>Existing Systems</span></p>
            <p className='text-medium mt-3'>Running a business means constant improvement and innovation.</p>
            <p className='text-medium mt-3'>Improving systems always leads to faster product delivery and more revenue. And there is always room for improvement!</p>
          </div>
        </div>

        {/* panel 5 */}
        <div className="grid-element flex-center">
          <div className="w-75 m-0-auto left-center-responsive">
            <div className="flex-basis-50">
              <p className='text-large text-orange w-75 text-center m-0-auto'>Building From The Ground Up</p>
              <p className='text-medium mt-3'>Want to try something new, but not sure if it's worth the time, energy and money?</p>
              <p className='text-medium mt-3'>We can help! With 25+ years of operations experience, we've seen a lot. We can advise on best practices and help build new departments from the ground up.</p>
            </div>
          </div>
        </div>
        <div className="grid-element flex-center">
          <img className="stock-image w-75 center" src={stockPhoto4} alt="project notes" />
        </div>
        <FooterPanel />
      </div>
    </div>
  )
}
