import React from 'react'
import './IconButton.css'

export default function IconButton(props) {
  return (
    <button className="IconButton" onClick={props.handleClick}>
      {props.children}
    </button>
  )
}
