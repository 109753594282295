import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faLocationPin, faPhone, faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faLinkedinIn, faInstagram } from '@fortawesome/free-brands-svg-icons';
import ContactUsButton from './ContactUsButton';
import ResoursifyButton from './ResoursifyButton';
import IconButton from './IconButton';

export default function FooterPanel() {
  const handleOpen = (e, route) => {
    e.preventDefault();
    window.open(route);
  }

  const handleScrollToTop = e => {
    e.preventDefault();
    window.scrollTo(0, 0);
  }

  return (
    <>
      <div className="span-2-desktop w-75 flex-wrap m-0-auto padding-t-3 padding-b-3">
        <div className="flex-basis-50 align-self-flex-start mb-6 flex-center-mobile">
          <ResoursifyButton />
          <ContactUsButton />
        </div>
        <div className="flex-basis-50 align-self-flex-start flex-center-mobile">
          <div>
            <p className='text-ubuntu-title'>About Us</p>
            <p className='text-medium mt-3'><FontAwesomeIcon icon={faPhone} style={{ marginRight: "1rem" }} /><a href="tel:4074369696" className="undecorated-link">(407) 436-9696</a></p>
            <p className='text-medium mt-3'><FontAwesomeIcon icon={faLocationPin} style={{ marginRight: "1rem" }} />680 S Cache Street, Jackson, WY <span style={{ display: "block" }}>Suite 100</span></p>
            <p className='text-medium mt-3'><FontAwesomeIcon icon={faEnvelope} style={{ marginRight: "1rem" }} /> <a href="mailto:info@resoursify.com" className="undecorated-link">info@resoursify.com</a></p>
          </div>
          <div className='icon-container mt-5'>
            <IconButton handleClick={e => handleOpen(e, "https://www.resoursify.com")}>
              <FontAwesomeIcon icon={faLinkedinIn} style={{ marginRight: "1rem" }} />
            </IconButton>
            <IconButton handleClick={e => handleOpen(e, "https://www.resoursify.com")}>
              <FontAwesomeIcon icon={faInstagram} style={{ marginRight: "1rem" }} />
            </IconButton>
            <IconButton handleClick={e => handleOpen(e, "https://www.resoursify.com")}>
              <FontAwesomeIcon icon={faFacebook} style={{ marginRight: "1rem" }} />
            </IconButton>
          </div>
        </div>
      </div>
      <div className="fixed-bottom flex-end text-medium">
        <IconButton handleClick={handleScrollToTop}>
          <FontAwesomeIcon icon={faArrowUp} style={{ marginRight: "1rem" }} />
        </IconButton>
      </div>
    </>
  )
}
