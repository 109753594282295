import React from 'react'
import './Navbar.css';
import { useNavigate } from "react-router-dom";
import resoursifyLogo from "../images/resoursify_192.png";
import { getDoesRouteMatchCurrentPage } from '../services/url';


export default function Navbar() {

  const navigate = useNavigate();

  const handleRedirect = (e, route) => {
    e.preventDefault();
    if (!!route) {
      const doesRouteMatchCurrentPage = getDoesRouteMatchCurrentPage(route, !!window && window.location);
      if (doesRouteMatchCurrentPage) {
        !!window && !!window.scrollTo && window.scrollTo(0, 0);
      } else {
        return navigate(route);
      }
    }
  }
  return <></>
  return (
    <div className="Navbar">
      <button onClick={e => handleRedirect(e, '/')}><p className='Navbar-title'>resoursify</p></button>
      <button onClick={e => handleRedirect(e, '/contact')}><p className='Navbar-text'>Contact Us</p></button>
      {/* <button onClick={e => handleRedirect(e, '/')}><img className="Navbar-logo" src={resoursifyLogo} alt="home"/></button> */}
    </div>
  )
}
